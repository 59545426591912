import React, { useContext } from 'react';
import { PropsWithClassProps } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Logo } from '@components/atoms/Logo/Logo';
import { NavItem } from '@components/atoms/NavItem/NavItem';
import { SearchButton } from '@components/atoms/SearchButton/SearchButton';
import AppContext from '@lib/AppContext';
import { HeaderButton } from '../../atoms/HeaderButton/HeaderButton';
import { HeaderNav } from '../../molecules/HeaderNav/HeaderNav';

export interface HeaderProps extends PropsWithClassProps {
  filled?: boolean;
  hideMobileNav?: boolean;
  disableTopSpacing?: boolean;
}

export const Header = ({ filled, hideMobileNav, disableTopSpacing }: HeaderProps) => {
  const context = useContext(AppContext);
  const mobileSubItems = context.header.navigation?.items.slice(2);

  return (
    <>
      <header
        className={twMerge(
          'pointer-events-none fixed z-60 grid h-26 w-full content-center items-center gap-x-1.5 px-4 text-white sm:gap-0 lg:h-24 lg:px-12 xl:px-16',
          filled ? 'bg-gray-900' : 'bg-gray-900 lg:bg-gray-900/0',
          hideMobileNav ? 'h-[4.65rem]' : 'h-26',
        )}
      >
        <div className="grid grid-cols-[1fr,auto,1fr] items-center gap-2">
          <Logo responsive link />
          <HeaderNav classProps={{ root: 'self-center' }} />
          <div className={'pointer-events-auto flex items-center justify-end gap-1.5 md:gap-4'}>
            <div className="block lg:block">
              <HeaderButton
                link={{
                  title: 'ABO',
                  href: 'https://www.tv-media.at/abo',
                  target: '_blank',
                  rel: 'noopener noreferrer',
                }}
              />
            </div>
            <SearchButton />
          </div>
        </div>
        {!hideMobileNav && (
          <nav className="pointer-events-auto flex justify-center gap-4 text-[11px] sm:gap-3 lg:hidden">
            {mobileSubItems?.map((item, index) => <NavItem item={item} key={index} type="sub" />)}
          </nav>
        )}
      </header>
      {!disableTopSpacing && <div className={twMerge('lg:hidden', hideMobileNav ? 'h-[4.65rem]' : 'h-26')} />}
    </>
  );
};
